<template>
  <Dialog scrollable :common-dialog="visitDialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      <v-layout v-if="visitDialog">
        <v-flex md6 class="d-flex">
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            Quotation #{{ visitDetail.barcode }}
          </h1>
          <!-- <Priority
            v-if="detail.priority"
            :priority="detail.priority"
          ></Priority> -->
          <template v-if="visitDetail.status">
            <CustomStatus
              :status="visitDetail.status"
              endpoint="quotation/status"
            ></CustomStatus>
          </template>
        </v-flex>
        <v-flex md6 class="d-flex justify-end">
          <div v-if="!pageLoading" class="d-flex justify-end">
            <template
              v-if="visitDetail.status == 3 && !visitDetail.is_revised && false"
            >
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="statusLoading"
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                    v-bind="attrs"
                    v-on="on"
                    v-on:click="updateMoreAction('revise')"
                  >
                    <v-icon left>mdi-update</v-icon> Revise
                  </v-btn>
                </template>
                <span>Click here to revise Quote</span>
              </v-tooltip>
            </template>
            <template
              v-if="
                visitDetail.status != 7 &&
                visitDetail.status != 6 &&
                visitDetail.status != 3 &&
                visitDetail.status != 2 &&
                visitDetail.status != 5 &&
                visitDetail.status != 8 &&
                false
              "
            >
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="statusLoading"
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                    v-bind="attrs"
                    v-on="on"
                    v-on:click="updateMoreAction('edit')"
                  >
                    <v-icon left>mdi-pencil</v-icon> Edit
                  </v-btn>
                </template>
                <span>Click here to edit Quote</span>
              </v-tooltip>
            </template>
            <v-menu
              v-if="false"
              transition="slide-y-transition"
              bottom
              content-class="custom-menu-list"
              offset-y
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="statusLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  v-bind="attrs"
                  v-on="on"
                >
                  More... <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-for="(more, index) in moreActions">
                  <v-list-item
                    link
                    v-on:click="updateMoreAction(more.action)"
                    :key="index"
                    :disabled="statusLoading || more.disabled"
                  >
                    <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                      <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="font-weight-500 font-size-14">{{
                      more.title
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>

            <v-btn
              class="ml-2 custom-grey-border custom-bold-button"
              v-on:click="$emit('close', true)"
            >
              Close
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <v-container>
        <v-row>
          <v-col cols="5" class="pb-0">
            <h3 v-if="customer.customer">
              <router-link
                :to="
                  getDefaultRoute('customer.detail', {
                    params: {
                      id: customer.customer,
                    },
                  })
                "
                >{{ customer.display_name }}
              </router-link>
            </h3>
            <span
              class="color-custom-blue font-weight-700 font-size-20 text-truncate"
              style=""
              >{{ visitDetail.job_title }}
            </span>
            <v-layout class="my-4">
              <v-flex md6 class="mr-2 custom-border-right" v-if="false">
                <table width="100%">
                  <tr>
                    <td class="font-weight-600">Service Location</td>
                  </tr>
                  <tr>
                    <td class="py-0 font-size-18 font-weight-500">
                      <a
                        target="_blank"
                        :href="`https://maps.google.com/?q=${property.property_address}`"
                        >{{ property.property_address }}</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-600">Contact details</td>
                  </tr>
                  <tr
                    v-if="
                      visitDetail.property_person &&
                      visitDetail.property_person.display_name
                    "
                  >
                    <td class="py-0 font-size-18 font-weight-500">
                      {{ visitDetail.property_person.display_name }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      visitDetail.property_person &&
                      visitDetail.property_person.primary_phone
                    "
                  >
                    <td class="py-0 font-size-18 font-weight-500">
                      {{ visitDetail.property_person.primary_phone }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      visitDetail.property_person &&
                      visitDetail.property_person.primary_email
                    "
                  >
                    <td class="py-0 font-size-18 font-weight-500">
                      {{ visitDetail.property_person.primary_email }}
                    </td>
                  </tr>
                </table>
              </v-flex>
              <v-flex md12 class="ml-2">
                <table width="100%">
                  <tr>
                    <td class="font-weight-600">Billing Address</td>
                  </tr>
                  <tr>
                    <td class="py-0 font-size-18 font-weight-500">
                      <a
                        target="_blank"
                        :href="`https://maps.google.com/?q=${billing.property_address}`"
                        >{{ billing.property_address }}</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-600">Contact details</td>
                  </tr>
                  <tr
                    v-if="
                      visitDetail.billing_person &&
                      visitDetail.billing_person.display_name
                    "
                  >
                    <td class="py-0 font-size-18 font-weight-500">
                      {{ visitDetail.billing_person.display_name }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      visitDetail.billing_person &&
                      visitDetail.billing_person.primary_phone
                    "
                  >
                    <td class="py-0 font-size-18 font-weight-500">
                      {{ visitDetail.billing_person.primary_phone }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      visitDetail.billing_person &&
                      visitDetail.billing_person.primary_email
                    "
                  >
                    <td class="py-0 font-size-18 font-weight-500">
                      {{ visitDetail.billing_person.primary_email }}
                    </td>
                  </tr>
                </table>
              </v-flex>
            </v-layout>
          </v-col>
          <v-col md="7" class="pb-0 gray-background pl-4">
            <v-container fluid>
              <v-row dense>
                <v-col md="12">
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <p
                    v-else
                    class="m-0 font-weight-700 font-size-18 pt-3 pb-1 color-custom-blue"
                  >
                    <template v-if="updateDetailDialog.title">
                      <v-text-field
                        ref="title"
                        v-model.trim="updateDetail.title"
                        dense
                        filled
                        color="cyan"
                        label="Visit Title"
                        :rules="[
                          validateRules.minLength(
                            updateDetail.title,
                            'Visit Title',
                            1
                          ),
                          validateRules.maxLength(
                            updateDetail.title,
                            'Visit Title',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                        :loading="particuleFieldLoading"
                        :disabled="particuleFieldLoading"
                        @keydown.esc="onEscParticularField"
                        @keydown.enter="updateParticularField"
                      ></v-text-field>
                    </template>
                    <template v-else>
                      <!-- <span
                        :class="{
                          'custom-bottom-border': getPermission('visit:update'),
                        }"
                        >{{ visitDetail.title }}</span
                      > -->
                    </template>
                  </p>
                  <template v-if="false">
                    <v-skeleton-loader
                      class="custom-skeleton my-auto custom-skeleton-full-width mr-5"
                      type="sentences"
                      v-if="pageLoading"
                    >
                    </v-skeleton-loader>
                    <template v-else>
                      <template v-if="updateDetailDialog.instruction">
                        <v-textarea
                          ref="instruction"
                          auto-grow
                          v-model.trim="updateDetail.instruction"
                          dense
                          filled
                          color="cyan"
                          label="Instruction"
                          :rules="[
                            validateRules.minLength(
                              updateDetail.instruction,
                              'Description',
                              1
                            ),
                            validateRules.maxLength(
                              updateDetail.instruction,
                              'Description',
                              1024
                            ),
                          ]"
                          solo
                          flat
                          hide-details
                          row-height="25"
                          :loading="particuleFieldLoading"
                          :disabled="particuleFieldLoading"
                          @keydown.esc="onEscParticularField"
                          @keydown.enter="updateParticularField"
                        ></v-textarea>
                      </template>
                      <template v-else>
                        <div class="d-flex align-start">
                          <read-more
                            class="custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                            :class="{
                              'custom-bottom-border':
                                getPermission('visit:update'),
                            }"
                            more-str="read more"
                            :text="
                              visitDetail.instruction ||
                              '<em>No Instructions</em>'
                            "
                            link="#"
                            less-str="read less"
                            :max-chars="200"
                          >
                          </read-more>
                        </div>
                      </template>
                    </template>
                  </template>
                </v-col>
                <v-col md="12">
                  <v-skeleton-loader
                    class="custom-skeleton height-30px mr-2 my-auto custom-skeleton-full-width"
                    width="100"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <!--  <v-chip
                    v-else
                    class="my-2 font-weight-600"
                    color="chip-custom-blue"
                    label
                    outlined
                  >
                    {{ getScheduleType }}
                  </v-chip> -->
                  <template v-if="pageLoading">
                    <v-skeleton-loader
                      v-for="(d, i) in 2"
                      :key="i"
                      class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                      :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <table class="width-100">
                      <!-- <tr v-if="false">
                        <td class="font-size-18 pt-3 pb-1" width="200">
                          Job number
                        </td>
                        <td class="font-weight-600 font-size-18 pt-3 pb-1">
                          {{ detail.barcode }}
                        </td>
                      </tr> -->
                      <tr>
                        <td class="font-size-18 py-1" width="200">
                          Quotation number
                        </td>
                        <td class="font-weight-600 font-size-18 py-1">
                          <template>
                            <router-link
                              :to="{
                                name: 'admin.quotation.detail',
                                params: {
                                  id: visitDetail.id,
                                },
                                query: {
                                  tab: 'pdfHistory',
                                },
                              }"
                              target="_blank"
                            >
                              <Barcode
                                dense
                                small
                                :barcode="visitDetail.barcode"
                              ></Barcode>
                              <!-- {{ visitDetail.barcode }} -->
                            </router-link>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">Date</td>
                        <td class="font-weight-600 font-size-18 py-1">
                          {{ visitDetail.date }}
                          <!-- <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <a
                                v-bind="attrs"
                                v-on="on"
                                href="javascript:void(0)"
                                v-on:click="downloadVisit"
                                class="text-uppercase max-content-width"
                                >{{ getAppointmentText }}</a
                              >
                            </template>
                            <span>Click here to download Appointment</span>
                          </v-tooltip> -->
                          <!-- <v-tooltip
                            top
                            content-class="custom-top-tooltip"
                            v-if="
                              visitDetail.status != 4 &&
                              visitDetail.status != 2 &&
                              visitDetail.status != 5
                            "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                :disabled="isTimmerRunning"
                                icon
                                text
                                class="mx-2"
                                v-on:click="appointmentUpdateDialog = true"
                                ><v-icon v-bind="attrs" v-on="on" color="cyan"
                                  >mdi-clock-fast</v-icon
                                ></v-btn
                              >
                            </template>
                            <span>Click here to reschedule Appointment</span>
                          </v-tooltip> -->
                        </td>
                      </tr>
                      <tr v-if="visitDetail.sales">
                        <td class="font-size-18 py-1" width="200">
                          Sales Representatives
                        </td>
                        <td class="font-weight-600 font-size-18 py-1">
                          <span>
                            <v-chip
                              style="height: 26px"
                              class="mx-2"
                              color="cyan white--text"
                              label
                            >
                              <v-icon small left> mdi-account-circle </v-icon>
                              {{ visitDetail.sales }}
                            </v-chip>
                          </span>
                        </td>
                      </tr>
                      <tr v-if="getVisitScheduleType()">
                        <td class="font-size-18 py-1" width="200">Schedule</td>
                        <td class="font-weight-600 font-size-18 py-1">
                          <read-more
                            class="custom-read-more font-weight-600 font-size-16 pb-1 pr-2 custom-gray-color"
                            more-str="read more"
                            :text="getVisitScheduleType()"
                            link="#"
                            less-str="read less"
                            :max-chars="60"
                          >
                          </read-more>
                        </td>
                      </tr>
                    </table>
                    <br />

                    <v-simple-table
                      fixed-header
                      height="20vh"
                      v-if="visitDetail.download_histories.length > 0"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <!-- <th width="300" class="simple-table-th">Description</th> -->
                            <!-- <th width="300" class="simple-table-th">Remarks</th> -->
                            <!-- <th width="300" class="simple-table-th">IP Address</th> -->
                            <!-- <th width="300" class="simple-table-th">Quote Date</th> -->
                            <th width="300" class="simple-table-th">
                              Valid Till
                            </th>
                            <!-- <th width="300" class="simple-table-th">Created Time</th> -->
                            <th width="300" class="simple-table-th">
                              Download
                            </th>
                            <!-- <th width="300" class="simple-table-th">Converted</th> -->
                            <!-- <th width="300" class="simple-table-th">Attachments</th> -->
                            <!-- <th width="300" class="simple-table-th"></th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <template
                            v-if="visitDetail.download_histories.length > 0"
                          >
                            <tr
                              v-for="(
                                history, index
                              ) in visitDetail.download_histories"
                              :key="index"
                            >
                              <!-- <td
                  class="simple-table-td"
                  style="padding-left: 15px !important"
                >
                  {{ history.description }}
                  <span class="text--secondary">
                    <template v-if="history.added_by">
                      Downloaded by
                      {{ history.added_by.display_name }}</template
                    >
                  </span>
                </td> -->
                              <!-- <td
                  class="simple-table-td"
                  width="200"
                  style="padding-left: 15px !important"
                >
                  {{ history.checked_remark_pdf }}
                </td> -->
                              <!-- <td
                  class="simple-table-td"
                  width="200"
                  style="padding-left: 15px !important"
                >
                  {{ history.ip_address }}
                </td> -->
                              <!--  <td
                  class="simple-table-td"
                  width="200"
                  style="padding-left: 15px !important"
                >
                  {{ formatDate(history.download_date) }}
                </td> -->
                              <td
                                v-if="
                                  history.is_sent == 1 || history.checked == 1
                                "
                                class="simple-table-td"
                                width="200"
                                style="padding-left: 15px !important"
                              >
                                <!-- <template>
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      v-if="history.status > 0"
                      class="d-inline mx-2 custom-status font-weight-600 custom-grey-border d-inline-table text-uppercase justify-center font-10"
                      color="black"
                      text-color="white"
                      label
                      small="small"
                    >
                      Expired
                    </v-chip>
                  </template> -->
                                {{ formatDate(history.valid_till) }}
                              </td>
                              <!-- <td
                  class="simple-table-td"
                  width="300"
                  style="padding-left: 15px !important"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-if="history.created_at"
                        class="my-1 custom-grey-border"
                        color="blue-grey darken-1"
                        text-color="white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-avatar left>
                          <v-icon>mdi-clock-outline</v-icon>
                        </v-avatar>
                        <p class="m-0 custom-nowrap-ellipsis">
                          {{ history.created_at }}
                        </p>
                      </v-chip>
                    </template>
                    <p class="m-0 custom-nowrap-ellipsis">
                      {{ formatDate(history.added_at) }}
                      {{ formatDateStartTime(history.added_at) }}
                    </p>
                  </v-tooltip>
                </td> -->
                              <td
                                v-if="
                                  history.is_sent == 1 || history.checked == 1
                                "
                                width="150"
                                class="font-size-16 font-weight-500"
                                align="left"
                                style="padding-left: 15px !important"
                              >
                                <v-tooltip
                                  top
                                  content-class="custom-top-tooltip"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      :disabled="!history.pdf_url"
                                      color="cyan"
                                      v-on:click.stop.prevent="
                                        downloadFile(history.pdf_url)
                                      "
                                      icon
                                    >
                                      <v-icon>mdi-file-pdf-box</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Download PDF{{ history.id }}</span>
                                </v-tooltip>
                              </td>
                              <!-- <td
                  class="simple-table-td"
                  width="200"
                  style="padding-left: 15px !important"
                >
                  <p>
                    <v-checkbox
                      color="cyan"
                      hide-details
                      v-model="history.checked"
                      :disabled="
                        history.quotation.status == 2 ||
                        Boolean(history.checked)
                      "
                      v-on:click="closeddata(history)"
                    ></v-checkbox>
                  </p>
                  <p
                    v-if="history.quotation && history.quotation.approved_date"
                  >
                    {{ formatDate(history.quotation.approved_date) }}
                  </p>
                </td> -->
                              <!-- <td
                  class="simple-table-td"
                  width="200"
                  style="padding-left: 15px !important"
                >
                  <v-layout
                    v-if="history.documents.length"
                    class="custom-border-top"
                  >
                    <v-flex md12 class="">
                      <FileTemplateName
                        :attachments="history.documents"
                        :allowUpload="false"
                        :allowDelete="false"
                        :isMinDisplay="6"
                      ></FileTemplateName>
                    </v-flex>
                  </v-layout>
                </td> -->
                              <!-- <td
                  class="simple-table-td"
                  width="200"
                  style="padding-left: 15px !important"
                >
                  <p>
                    <v-btn
                      :disabled="
                        history.quotation.status == 2 || history.is_sent
                      "
                      class="mx-2 custom-bold-button white--text"
                      color="cyan"
                      v-bind="attrs"
                      v-on="on"
                      v-on:click="sendMail(history)"
                    >
                      <v-icon left>mdi-send mdi-rotate-315</v-icon> Send Mail
                    </v-btn>
                  </p>

                  <v-tooltip bottom v-if="history.is_sent && history.send_date">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-if="history.created_at"
                        class="my-1 custom-grey-border"
                        color="blue-grey darken-1"
                        text-color="white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-avatar left>
                          <v-icon>mdi-clock-outline</v-icon>
                        </v-avatar>
                        <p class="m-0 custom-nowrap-ellipsis">
                          {{ history.send_at }}
                        </p>
                      </v-chip>
                    </template>
                    <p class="m-0 custom-nowrap-ellipsis">
                      {{ formatDateTime(history.send_date) }}
                    </p>
                  </v-tooltip>
                </td> -->
                            </tr>
                          </template>
                          <template v-else>
                            <template v-if="pageLoading">
                              <tr v-for="row in 5" :key="row">
                                <td>
                                  <v-skeleton-loader
                                    type="text"
                                    class="custom-skeleton table-rows-text"
                                  ></v-skeleton-loader>
                                </td>
                                <td>
                                  <v-skeleton-loader
                                    type="text"
                                    class="custom-skeleton table-rows-text"
                                  ></v-skeleton-loader>
                                </td>
                                <td>
                                  <v-skeleton-loader
                                    type="text"
                                    class="custom-skeleton table-rows-text"
                                  ></v-skeleton-loader>
                                </td>
                              </tr>
                            </template>
                            <tr v-else-if="!pageLoading">
                              <td colspan="3">
                                <p
                                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                                >
                                  <img
                                    :src="$assetURL('media/error/empty.png')"
                                    class="row-not-found-image"
                                  />
                                  Uhh... There are no history at the moment.
                                </p>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>

      <DeleteDialog :delete-dialog="cancelVisitDialog">
        <template v-slot:title>Cancel Confirmation</template>
        <template v-slot:text>
          <h3 class="font-size-16 font-weight-500 delete-text">
            Once you cancel
            <span class="font-weight-700">{{ visitDetail.barcode }}</span
            >, you won't be able to revert this later.<br />Are you sure you
            want to cancel
            <span class="font-weight-700">{{ visitDetail.barcode }}</span>
            ?
          </h3>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="cancelVisitDialog = false"
            >No! Cancel</v-btn
          >
          <v-btn
            color="red lighten-1 white--text"
            v-on:click="cancelVisitConfirm()"
            class="mx-2 custom-grey-border custom-bold-button"
            >Yes! Cancel</v-btn
          >
        </template>
      </DeleteDialog>

      <Dialog :commonDialog="appointmentUpdateDialog">
        <template v-slot:title>Reschedule Appointment</template>
        <template v-slot:body>
          <v-container fluid class="py-0" style="min-height: 22vh">
            <v-row>
              <v-col md="4" class="py-1 font-weight-600"> Date </v-col>
              <v-col md="4" class="py-1 font-weight-600"> Start Time </v-col>
              <v-col md="4" class="py-1 font-weight-600"> End Time </v-col>
              <v-col md="4" class="py-1">
                <Datepicker
                  :defaultDate="updateStartDate"
                  solo
                  v-on:update:date-picker="updateAppointmentDate"
                ></Datepicker>
              </v-col>
              <v-col md="4" class="py-1">
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  id="start-time"
                  :items="timeList"
                  v-model.trim="starTimereschdule"
                  placeholder="Start Time"
                  label="Start Time"
                  solo
                  flat
                  item-color="cyan"
                  hide-details
                />
              </v-col>
              <v-col md="4" class="py-1">
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  id="end-time"
                  :items="timeList"
                  v-model.trim="endTimereschdule"
                  placeholder="End Time"
                  label="End Time"
                  solo
                  flat
                  item-color="cyan"
                  hide-details
                />
              </v-col>
              <v-col md="12" class="py-2" v-if="false">
                <em
                  >All engineers will be removed from visit#
                  {{ visitDetail.barcode }}</em
                >
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="apptFormLoading || pageLoading"
            :loading="apptFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="updateAppointment"
          >
            Save
          </v-btn>
          <v-btn
            :disabled="apptFormLoading || pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="appointmentUpdateDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>

      <!-- Create Closer -->
      <template v-if="getPermission('closer:create') && closerDialog">
        <CreateTimeCloser
          v-if="runningTimer.id"
          is-visit-detail
          :ticket-id="ticketId"
          :visit-id="visitId"
          :timesheet-id="runningTimer.id"
          :timesheet="runningTimer.id"
          :visit-detail="visitDetail"
          :closer-dialog="closerDialog"
          :detail="detail"
          v-on:create:ticket-closer="createdTimeCloser"
          v-on:close-dialog="closerDialog = false"
        ></CreateTimeCloser>
      </template>
    </template>
  </Dialog>
</template>

<script>
import moment from "moment-timezone";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
//import Priority from "@/view/pages/partials/Priority.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import {
  PUT,
  QUERY,
  POST,
  PATCH,
  GET,
} from "@/core/services/store/request.module";
import JwtService from "@/core/services/jwt.service";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { toNumber } from "lodash";
import { downloadEvent } from "@/core/lib/ics.lib";
import DeleteDialog from "@/view/pages/partials/Delete.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
// import Timepicker from "@/view/pages/partials/Timepicker.vue";
// import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import CreateTimeCloser from "@/view/pages/job/partials/Create-Ticket-Closer.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  props: {
    visitDialog: {
      type: Boolean,
      default: false,
    },
    isCalendar: {
      type: Boolean,
      default: false,
    },
    visit: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    visit: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.loadContent();
        }
      },
    },
  },
  data() {
    return {
      moreActions: [],
      statusLoading: false,

      yourFormat: "hh:mm A",
      starTimereschdule: null,
      endTimereschdule: null,
      ticketId: null,
      visitId: null,
      resumeTimerDialog: false,
      assignFormLoading: false,
      assignFormValid: true,
      appointmentUpdateDialog: false,
      timesheetRunningDialog: false,
      apptFormLoading: false,
      allowUpdate: false,
      timerLoading: true,
      timerDialog: false,
      closerDialog: false,
      startTimerDialog: false,
      completeDialog: false,
      updateServiceFormDialog: false,
      assignServiceFormDialog: false,
      allTimerDialog: false,
      timer_remarks: null,
      audioDataURL: null,
      timerFormValid: true,
      timerFormLoading: false,
      recordingStarted: false,
      recordingStopped: false,
      loadingEngineers: false,
      engineerDialog: false,
      apptEngLoading: false,
      runningTimer: new Object(),
      isTimmerRunning: false,
      pageLoading: false,
      detail: new Object(),
      visitDetail: {},
      customer: new Object(),
      contactPerson: new Object(),
      property: new Object(),
      property_contact_person: new Object(),
      billing_contact_person: new Object(),
      billing: new Object(),
      visitNotes: new Object(),
      particuleFieldLoading: false,
      updateDetailDialog: {
        title: false,
        instruction: false,
      },
      updateDetail: {
        title: null,
        instruction: null,
      },
      updateAppointmentFormData: {
        date: null,
        start_time: null,
        end_time: null,
      },
      serviceFormList: new Array(),
      engineerList: new Array(),
      visitEngineer: null,
      userRunningTimers: new Array(),
      timeInterval: null,
      durationInterval: null,
      timerServiceForm: new Array(),
      timeIntervalLimit: 10000,
      timerDuration: {
        hour: "00",
        minute: "00",
        second: "00",
      },
      closer: {
        pdf_url: null,
      },
      assignedTeam: [],
      dbLineItems: [],
      newLineItems: [],
      cancelVisitDialog: false,
      cancelVisitLoading: false,
      cancelVisitBarcode: null,
      lineItemLoading: false,
    };
  },
  methods: {
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    downloadFile(url, prepend_url) {
      if (!url) {
        return false;
      }
      if (prepend_url) {
        url = `https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/${url}`;
      }
      window.open(url, "_blank");
    },
    checkQuotationDetails(param) {
      // console.log(param,"param");
      if (param) {
        this.$router.push({
          name: "quotation.detail",
          params: {
            id: param,
            tab: "pdfHistory",
          },
          target: "_blank",
        });
      }

      //  console.log(param,"param");
    },

    cancelVisitConfirm() {
      this.cancelVisitLoading = true;
      this.$store
        .dispatch(PUT, { url: `visit/${this.visitDetail.id}/mark-as-cancel` })
        .then(() => {
          this.loadContent();
          this.$emit("reload", true);
          this.cancelVisitDialog = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.cancelVisitLoading = false;
        });
    },
    downloadSchedule() {
      const ticketId = this.visitDetail.ticket.id;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}job/${ticketId}/download-schedule?token=${token}`;
      window.open(url, "_blank");
    },
    updateEngineer() {
      const visitId = this.visitDetail.id;
      const ticketId = this.visitDetail.ticket.id;
      const _this = this;
      if (!_this.assignedTeam.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select an engineer then try again.")
        );
        return false;
      }
      _this.apptEngLoading = true;
      _this.$store
        .dispatch(PUT, {
          url: "job/" + ticketId + "/visit/" + visitId + "/engineer",
          data: {
            engineer: _this.assignedTeam,
          },
        })
        .then(() => {
          _this.engineerList = [];
          _this.$nextTick(() => {
            _this.loadContent();
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.apptEngLoading = false;
        });
    },
    getAvailableEngineers() {
      const _this = this;
      _this.loadingEngineers = true;
      _this.$store
        .dispatch(QUERY, {
          url: "job/available-engineers",
          data: {
            start_date: moment(_this.visitDetail.started_at).format(
              "YYYY-MM-DD"
            ),
            start_time: moment(_this.visitDetail.started_at).format("HH:mm"),
            end_date: moment(_this.visitDetail.finished_at).format(
              "YYYY-MM-DD"
            ),
            end_time: moment(_this.visitDetail.finished_at).format("HH:mm"),
            force: 0,
            all: 1,
          },
        })
        .then(({ data }) => {
          _this.engineerList = data.engineers;
          // _this.engineerDialog = true;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.loadingEngineers = false;
        });
    },
    downloadPDF() {
      if (this.closer.pdf_url) {
        window.open(this.closer.pdf_url, "_blank");
      } else {
        const token = JwtService.getToken();
        const url = `${this.$apiURL}visit/${this.visitDetail.id}/closer/${this.closer.id}/download?token=${token}`;
        window.open(url, "_blank");
      }
    },
    routeToInvoice() {
      const { ticket } = this.visitDetail;

      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: {
            customer: this.customer.id,
            property: this.property.id,
            billing: this.billing.id,
            visit: this.visitDetail.id,
            ticket: ticket.id,
          },
        })
      );
    },
    closerServiceFormDialog() {
      const _this = this;
      _this.updateServiceFormDialog = false;
      // _this.getActiveTimer();
      _this.loadContent();
    },
    assignServiceForm() {
      const _this = this;

      if (!_this.$refs.assignServiceForm.validate()) {
        return false;
      }

      _this.assignFormLoading = true;

      _this.$store
        .dispatch(POST, {
          url: `job/${_this.detail.id}/visit/${_this.visit}/service-form/${_this.timerServiceForm}`,
          data: {
            timesheet: _this.runningTimer.id || null,
          },
        })
        .then(() => {
          _this.timerServiceForm = new Array();
          ServiceFormEventBus.$emit("reload:service-form");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.assignFormLoading = false;
          _this.assignServiceFormDialog = false;
          //_this.getActiveTimer();
        });
    },
    assignServiceFormDetail() {
      const _this = this;
      _this.timerLoading = true;

      _this.$store
        .dispatch(QUERY, {
          url: "job/service-forms",
          data: {
            timesheet: _this.runningTimer.id,
            customer: _this.customer.customer,
          },
        })
        .then(({ data }) => {
          _this.serviceFormList = data;
          _this.assignServiceFormDialog = true;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.timerLoading = false;
        });
    },
    createdTimeCloser() {
      this.closerDialog = false;
      this.$emit("close", true);
      this.$emit("reload", true);
      // this.getActiveTimer();
      this.loadContent();
    },
    updatedServiceForm() {
      this.updateServiceFormDialog = false;
      // this.getActiveTimer();
    },
    timesheetRunningClose() {
      const _this = this;
      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, { url: "running-time-sheet" })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
          _this.getServiceFormList();
        });
    },
    checkRunningTimerStatus() {
      const _this = this;
      _this.timerLoading = true;

      _this.$store
        .dispatch(GET, { url: "running-time-sheet" })
        .then(({ data }) => {
          if (_this.lodash.isEmpty(data) === false) {
            _this.userRunningTimers = data;
            _this.timesheetRunningDialog = true;
          } else {
            _this.getServiceFormList();
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.timerLoading = false;
        });
    },
    getServiceFormList() {
      const _this = this;
      _this.timerLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "job/service-forms",
          data: {
            customer: _this.customer.customer,
          },
        })
        .then(({ data }) => {
          _this.serviceFormList = data;
          _this.startTimerDialog = true;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.timerLoading = false;
          _this.timesheetRunningDialog = false;
        });
    },
    updateAppointmentDate(param) {
      this.updateAppointmentFormData.date = param;
    },
    updateAppointmentStartTime(param) {
      this.updateAppointmentFormData.start_time = param;
    },
    updateAppointmentEndTime(param) {
      this.updateAppointmentFormData.end_time = param;
    },
    updateAppointment() {
      const _this = this;
      _this.apptFormLoading = true;

      _this.$store
        /* .dispatch(PATCH, {
          url:
            "job/" + _this.detail.id + "/visit/" + _this.visit + "/re-schedule",
          data: {
            date: _this.updateAppointmentFormData.date
              ? moment(_this.updateAppointmentFormData.date).format(
                  "YYYY-MM-DD"
                )
              : moment().format("YYYY-MM-DD"),
            start_time: _this.updateAppointmentFormData.start_time
              ? moment(_this.updateAppointmentFormData.start_time, [
                  "h:mm A",
                ]).format("HH:mm")
              : moment().startOf("day").format("HH:mm"),
            end_time: _this.updateAppointmentFormData.end_time
              ? moment(_this.updateAppointmentFormData.end_time, [
                  "h:mm A",
                ]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),
          },
        }) */
        .dispatch(PATCH, {
          url:
            "job/" + _this.detail.id + "/visit/" + _this.visit + "/re-schedule",
          data: {
            date: _this.updateAppointmentFormData.date
              ? moment(_this.updateAppointmentFormData.date).format(
                  "YYYY-MM-DD"
                )
              : moment().format("YYYY-MM-DD"),
            end_date: _this.updateAppointmentFormData.end_date
              ? moment(_this.updateAppointmentFormData.end_date).format(
                  "YYYY-MM-DD"
                )
              : moment().format("YYYY-MM-DD"),
            start_time: _this.starTimereschdule
              ? moment(_this.starTimereschdule, ["hh:mm a"]).format("HH:mm")
              : moment().startOf("day").format("HH:mm"),
            end_time: _this.endTimereschdule
              ? moment(_this.endTimereschdule, ["hh:mm a"]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),
          },
        })
        .then(() => {
          _this.appointmentUpdateDialog = false;
          _this.loadContent();
          _this.$emit("reload-calendar", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.apptFormLoading = false;
        });
    },
    startTimer() {
      const _this = this;
      if (!_this.$refs.timerStartForm.validate()) {
        return false;
      }

      _this.timerFormLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" + _this.detail.id + "/visit/" + _this.visit + "/timer/start",
          data: {
            detail: _this.timer_remarks,
            service_form: _this.timerServiceForm,
          },
        })
        .then(() => {
          _this.$emit("reload", true);
          _this.startTimerDialog = false;
          _this.timerServiceForm = new Array();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.timerFormLoading = false;
          // _this.getActiveTimer();
          _this.loadContent();
        });
    },
    startTimerWithLater() {
      const _this = this;
      if (_this.isTimmerRunning) {
        _this.closerDialog = true;
      } else {
        _this.timerFormLoading = true;
        _this.$store
          .dispatch(PATCH, {
            url:
              "job/" +
              _this.detail.id +
              "/visit/" +
              _this.visit +
              "/timer/start",
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.$emit("reload", true);
            _this.timerFormLoading = false;
            // _this.getActiveTimer(true);
            _this.loadContent();
          });
      }
    },
    setTimerDuration() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.runningTimer) === false) {
        let startDate = _this.lodash.toString(_this.runningTimer.date);
        if (_this.runningTimer.start_at) {
          startDate = _this.lodash.toString(
            startDate + " " + _this.runningTimer.start_at
          );
        }

        if (startDate && moment(startDate, ["YYYY-MM-DD HH:mm:ss"]).isValid()) {
          startDate = moment(startDate, ["YYYY-MM-DD HH:mm:ss"]);

          let duration = moment.duration(moment().diff(startDate));

          let hours = parseInt(duration.asHours());
          let minutes = parseInt(duration.asMinutes()) % 60;
          let seconds = parseInt(duration.asSeconds()) % 60;

          _this.timerDuration = {
            hour: hours > 0 ? _this.lodash.padStart(hours, 2, "0") : "00",
            minute: minutes > 0 ? _this.lodash.padStart(minutes, 2, "0") : "00",
            second: seconds > 0 ? _this.lodash.padStart(seconds, 2, "0") : "00",
          };
        }
      }
    },
    updateTimerDuration() {
      const _this = this;
      clearInterval(_this.durationInterval);
      _this.durationInterval = setInterval(_this.setTimerDuration, 1000);
    },
    recordingStartedUpdate(param) {
      this.recordingStarted = param;
    },
    recordingStoppedUpdate(param) {
      this.recordingStopped = param;
    },
    stopRunningTimer() {
      const _this = this;

      if (_this.recordingStarted === true && _this.recordingStopped === false) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please stop the audio recorder then try again.")
        );
        return false;
      }

      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.runningTimer.ticket +
            "/visit/" +
            _this.runningTimer.visit +
            "/timer/" +
            _this.runningTimer.id +
            "/end",
          data: { detail: _this.timer_remarks, audio: _this.audioDataURL },
        })
        .then(() => {
          _this.timerDialog = false;
          _this.timer_remarks = null;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
          // _this.getActiveTimer();
          _this.loadContent();
        });
    },
    markAsComplete() {
      const _this = this;
      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.visit +
            "/update-status/1",
        })
        .then(() => {
          _this.loadContent();
          _this.$emit("reload", true);
          _this.completeDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    stopAllTimers() {
      const _this = this;
      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url: "visit/" + _this.visit + "/stop-all-timer",
        })
        .then(() => {
          _this.loadContent();
          _this.$emit("reload", true);
          _this.allTimerDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    checkTimerStatus() {
      const _this = this;
      clearInterval(_this.timeInterval);
      _this.timeInterval = setInterval(
        // _this.getActiveTimer,
        _this.timeIntervalLimit
      );
    },
    /*  getActiveTimer(closer) {
      const _this = this;
      if (_this.visitDetail.status < 4) {
        _this.timerLoading = true;
        _this.$store
          .dispatch(GET, {
            url: "visit/" + _this.visit + "/running",
          })
          .then(({ data }) => {
            if (data && !isEmpty(data)) {
              _this.runningTimer = new Object(data);
              _this.ticketId = data.ticket;
              _this.visitId = data.visit;
            }

            _this.isTimmerRunning = !!data;

            if (_this.isTimmerRunning) {
              _this.resumeTimerDialog = false;
            }

            if (closer) {
              _this.$nextTick(() => {
                _this.closerDialog = true;
              });
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.timerLoading = false;
          });
      }
    }, */
    updateField(type) {
      const _this = this;
      if (_this.canUpdateVisit) {
        if (!_this.allowUpdate) {
          return false;
        }
        _this.onEscParticularField();
        _this.updateDetailDialog[type] = true;
        _this.updateDetail[type] = _this.visitDetail[type];
        setTimeout(function () {
          _this.$refs[type].focus();
        }, 50);
      }
    },
    onEscParticularField() {
      this.updateDetailDialog = {
        title: false,
        instruction: false,
      };
      this.updateDetail = {
        title: null,
        instruction: null,
      };
    },
    patchVisit(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(PATCH, {
              url: "visit/" + _this.visit,
              data,
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    updateParticularField() {
      const _this = this;
      _this.particuleFieldLoading = true;
      if (_this.canUpdateVisit) {
        _this
          .patchVisit(_this.updateDetail)
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.loadContent();
            _this.particuleFieldLoading = false;
            _this.onEscParticularField();
          });
      }
    },
    resumeTimer() {
      const _this = this;
      _this.timerFormLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "visit/" + _this.visit + "/resume" })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.timerFormLoading = false;
          // _this.getActiveTimer();
          _this.loadContent();
        });
    },
    downloadVisit() {
      try {
        let startDateArray = moment(this.visitDetail.started_at)
          .format("YYYY-MM-DD")
          .split("-")
          .map((row) => toNumber(row));

        let startTimeArray = moment(this.visitDetail.started_at)
          .format("HH:mm")
          .split(":")
          .map((row) => toNumber(row));

        let endDateArray = moment(this.visitDetail.finished_at)
          .format("YYYY-MM-DD")
          .split("-")
          .map((row) => toNumber(row));

        let endTimeArray = moment(this.visitDetail.finished_at)
          .format("HH:mm")
          .split(":")
          .map((row) => toNumber(row));

        if (startTimeArray[0] == "00") {
          startTimeArray = new Array();
        }

        const visit = {
          start: this.lodash.concat(startDateArray, startTimeArray),
          end: this.lodash.concat(endDateArray, endTimeArray),
          title: this.visitDetail.title,
          description: this.visitDetail.instruction,
          location: this.property.property_address,
          url: process.env.VUE_APP_BASE_URL,
          geo: {
            lat: toNumber(this.property.latitude),
            lon: toNumber(this.property.longitude),
          },
          categories: this.detail.ticket_type,
          status: "CONFIRMED",
          organizer: {
            name: this.detail.added_by.display_name,
            email: this.detail.added_by.user_email,
          },
          attendees: this.visitDetail.engineers.map((row) => {
            if (row.engineer) {
              return {
                name: row.engineer.full_name,
                email: row.engineer.primary_email,
              };
            }
          }),
        };
        downloadEvent(visit);
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
    getVisitScheduleType() {
      return this.detail ? this.detail.schedule_message : "";
    },
    loadContent() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "quotation/" + _this.visit,
        })
        .then(({ data }) => {
          _this.visitDetail = data;
          _this.moreActions = data.more_actions;
          _this.starTimereschdule = moment(_this.visitDetail.started_at).format(
            "hh:mm A"
          );
          _this.endTimereschdule = moment(_this.visitDetail.finished_at).format(
            "hh:mm A"
          );

          _this.closer = data.closer;
          _this.detail = data.ticket;
          _this.customer = data.customer;
          _this.contactPerson = data.contact_person || new Object();
          _this.property = data.property || new Object();
          _this.property_contact_person =
            data.property_contact_person || new Object();
          _this.billing_contact_person =
            data.billing_contact_person || new Object();
          _this.billing = data.billing || new Object();
          _this.visitNotes = data.notes;
          _this.allowUpdate = true;
          if (_this.visitDetail.status === 4) {
            _this.allowUpdate = false;
            clearInterval(_this.timeInterval);
            clearInterval(_this.durationInterval);
          }

          if (
            _this.visitDetail.engineers &&
            _this.visitDetail.engineers.length
          ) {
            _this.assignedTeam = _this.visitDetail.engineers.map(
              (row) => row.engineer
            );
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          //_this.getActiveTimer();
          _this.pageLoading = false;
        });
    },
  },
  components: {
    Dialog,
    // Priority,
    Barcode,
    Datepicker,
    // Timepicker,
    // VueTimepicker,
    CreateTimeCloser,
    DeleteDialog,
    CustomStatus,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
    updateStartDate() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        return moment(this.visitDetail.started_at).format("YYYY-MM-DD");
      }
      return null;
    },
    updateStartTime() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        return moment(this.visitDetail.started_at).format("hh:mm A");
      }
      return null;
    },
    updateEndTime() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        return moment(this.visitDetail.finished_at).format("hh:mm A");
      }
      return null;
    },
    canUpdateVisit() {
      if (this.visitDetail && this.visitDetail.status != 1) {
        return false;
      }
      return this.getPermission("visit:update");
    },
    getAppointmentText() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        let date = this.formatDate(this.visitDetail.started_at);
        let startTime = moment(this.visitDetail.started_at).format("hh:mm A");
        let endTime = moment(this.visitDetail.finished_at).format("hh:mm A");
        return date + " " + startTime + " - " + endTime;
      }
      return null;
    },
    getScheduleType() {
      if (this.detail) {
        if (this.detail.type === 1) {
          return "One-Off Job";
        }
        if (this.detail.type === 2) {
          return "Recurring Job";
        }
      }
      return null;
    },
  },
};
</script>
